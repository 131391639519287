
import * as Components from "./Craveoaks"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "craveoaks"
}

