import React from 'react'
import {Link} from 'react-router-dom'
import { Subscribe } from 'unstated';
import CartContainer from '../react/CartContainer'
import {getRestaurantId} from '../utils'
import Cashback from '../Checkout/Cashback'
import PromoCode from '../Checkout/PromoCode'




class CashPayment extends React.Component {
  state = {
    cashback: false,
    success: false,
    err: false
  };

  onInputChange = (e) => {
    this.props.cart.setStateAndSave({[e.target.name]: e.target.value})
  };

  onSubmit = (event) => {
    event.preventDefault()
  };

  render() {

    let cart = this.props.cart
    let shopId = this.props.shopId
    let showVerificationText = ['westernwok', 'craveoaks'].indexOf(shopId) !== -1


    return (
      <section aria-label="personal information" className="checkout__cash-payment">
       <h2 className="checkout-section-header">Contact Information</h2>
        <div className="form-wrapper">
          {/* <p>Requires text messege authentication.</p> */}
          <form action="" 
          autoComplete="off"
          autoCorrect="off"
          onSubmit={this.onSubmit}>
            <p>
              <label htmlFor="customer-name">Name:</label>
              <input
                name="name"
                value={cart.state.name}
                onChange={this.onInputChange}
                required

                type="text" id="customer-name" />
            </p>


            <p>
              <label
                htmlFor="phone-num">Phone:</label>
              {showVerificationText &&
                <div
                
                  style={{

                    fontFamily: 'Helvetica',
                    fontSize: "0.8em",
                    margin: "0.2em 0",
                    padding: "0.2em",
                    display: "inline-block",
                    background: "#f8ff22"
                  }}
                  className="verify">
                  we must be able to contact you for us to make the order.
                </div>
              }
              <input
                style={{
                  display: 'block',
                }}
                name="phone"
                value={cart.state.phone}
                onChange={this.onInputChange}
                autoComplete="off"
                autoCorrect="off"
                required
                // placeholder="###-###-####"
                type="tel" id="phone-num" />

            </p>



          </form>
          <Cashback shopId={this.props.shopId}></Cashback>


          <PromoCode></PromoCode>
        </div>

      </section>
    )
  }
}



const WrappedCashPayment = (props) => {
  return (
    <Subscribe to={[CartContainer]} >
      {(cart) => {
        return(
          <CashPayment {...props} cart={cart}></CashPayment>
        )
      }}
    </Subscribe>
  )
}

export default WrappedCashPayment
